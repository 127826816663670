import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';






// Angular Material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';

// NGX Material File Input
import { MaterialFileInputModule } from 'ngx-material-file-input';

// NGX ScrollTo
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

// MDB BOOTSTRAP
import { MDBBootstrapModule } from 'angular-bootstrap-md';

// Utilidades creadas por nosotros
import { GlobalUtilities } from './utilities/clases/global_utilities';
import { sanitizeHtmlPipe } from './utilities/servicios/custom-pipes/sanitize-html.pipe';
import { SafePipe } from './utilities/servicios/custom-pipes/safe.pipe';
import { DotDotDotPipe } from './utilities/servicios/custom-pipes/dotdotdot.pipe';
import { DatePipe } from '@angular/common';
import { SafeHtmlPipe } from './utilities/servicios/custom-pipes/safeHtmlPipe.pipe';
import { RunScriptsDirective } from './utilities/directivas/RunScriptsDirective';
import { DynamicScriptLoaderService } from './utilities/servicios/dynamic-script-loader.service';

// Cookies
import { CookieService } from 'ngx-cookie-service';

//pagination
import { NgxPaginationModule } from 'ngx-pagination';

// importar locales de idiomas
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePy from '@angular/common/locales/es-PY';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';

// registrar los locales con el nombre que quieras utilizar a la hora de proveer
registerLocaleData(localePy, 'es');
registerLocaleData(localeEn, 'en')
registerLocaleData(localeFr, 'fr');

// WYSIWYG
import { AngularEditorModule } from '@kolkov/angular-editor';

// Angular Google Maps
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';

// Componentes Public
import { HomeComponent } from './home/home.component';
import { ContactoComponent, texto_legal_dialog_contacto } from './contacto/contacto.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { BlogComponent } from './blog/blog.component';
import { DetalleBlogComponent } from './detalle-blog/detalle-blog.component';
import { HabitacionesComponent } from './habitaciones/habitaciones.component';
import { DetalleHabitacionComponent } from './detalle-habitacion/detalle-habitacion.component';
import { JardinComponent } from './jardin/jardin.component';


// Componentes Partials
import { HeaderComponent } from './partials/header/header.component';
import { FooterComponent, texto_legal_dialog } from './partials/footer/footer.component';
import { PartialContactoComponent, texto_legal_dialog_partial_contacto } from './partials/partial-contacto/partial-contacto.component';
import { MenuComponent, texto_legal_menu_dialog, menuReservarRestauranteDialog } from './partials/menu/menu.component';
import { PartialBlogComponent } from './partials/partial-blog/partial-blog.component';
import { PartialHabitacionesComponent } from './partials/partial-habitaciones/partial-habitaciones.component';

//Componentes Admin
import { AdminLoginComponent } from './admin/login/login.component';
import { AdminHomeComponent } from './admin/home/home.component';
import { AdminContactoComponent, fullMessageDialog } from './admin/contacto/contacto.component';
import { ListadoBlogComponent } from './admin/blog/listado-blog/listado-blog.component';
import { NewBlogComponent, addRelatedDialogNewBlog } from './admin/blog/new-blog/new-blog.component';
import { EditBlogComponent, addRelatedDialogEditBlog } from './admin/blog/edit-blog/edit-blog.component';
import { SidenavComponent } from './admin/partials/sidenav/sidenav.component';
import { ListadoCaracteristicasComponent, removeCaracteristicasDialog } from './admin/caracteristicas/listado-caracteristicas/listado-caracteristicas.component';
import { NewCaracteristicaComponent } from './admin/caracteristicas/new-caracteristica/new-caracteristica.component';
import { EditCaracteristicaComponent } from './admin/caracteristicas/edit-caracteristica/edit-caracteristica.component';
import { EditServicioComponent } from './admin/servicios/edit-servicio/edit-servicio.component';
import { NewServicioComponent } from './admin/servicios/new-servicio/new-servicio.component';
import { ListadoServiciosComponent, removeServiciosDialog } from './admin/servicios/listado-servicios/listado-servicios.component';

//Componentes Error
import { NotFoundComponent } from './not-found/not-found.component';

// Cargar Scripts
import { LazyLoadScriptService } from './utilities/servicios/lazy-load-script.service';

// Slick Carousel
import { SlickCarouselModule } from 'ngx-slick-carousel';

// On Viewport
import { InViewportModule } from 'ng-in-viewport';
import { HotelComponent } from './hotel/hotel.component';
import { GastronomiaComponent, gastronomyReservarRestauranteDialog } from './gastronomia/gastronomia.component';
import { CartaComponent, cartaReservarRestauranteDialog } from './carta/carta.component';


// Datepicker with hour
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';

// HourPicker
import { OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeIntl, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';


export class DefaultIntl extends OwlDateTimeIntl {
  setBtnLabel = 'Aceptar';
  cancelBtnLabel = 'Cancelar';
}



export const MY_MOMENT_FORMATS = {
  /* parseInput: 'l LT', */
  parseInput: 'YYYY-MM-DD',
  fullPickerInput: 'YYYY-MM-DD',
  datePickerInput: 'YYYY-MM-DD',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

export const DateFormat = {
  parse: {
  dateInput: 'input',
  },
 display: {
 dateInput: 'yyyy-MM-dd',
 monthYearLabel: 'MMMM YYYY',
 dateA11yLabel: 'MM/DD/YYYY',
 monthYearA11yLabel: 'MMMM YYYY',
 }
 };



import { OrderModule } from 'ngx-order-pipe';
import { RoomserviceComponent } from './roomservice/roomservice.component';
/* import { MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular-material-components/moment-adapter'; */



@NgModule({
  declarations: [
    AppComponent,
    AdminLoginComponent,
    sanitizeHtmlPipe,
    SafePipe,
    DotDotDotPipe,
    SafeHtmlPipe,
    RunScriptsDirective,
    AdminHomeComponent,
    AdminContactoComponent,
    fullMessageDialog,
    addRelatedDialogNewBlog,
    addRelatedDialogEditBlog,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    NotFoundComponent,
    ListadoBlogComponent,
    NewBlogComponent,
    EditBlogComponent,
    ContactoComponent,
    ThankYouComponent,
    PartialContactoComponent,
    MenuComponent,
    texto_legal_menu_dialog,
    menuReservarRestauranteDialog,
    BlogComponent,
    DetalleBlogComponent,
    PartialBlogComponent,
    texto_legal_dialog,
    texto_legal_dialog_contacto,
    texto_legal_dialog_partial_contacto,
    SidenavComponent,
    PartialHabitacionesComponent,
    HabitacionesComponent,
    DetalleHabitacionComponent,
    HotelComponent,
    GastronomiaComponent,
    gastronomyReservarRestauranteDialog,
    CartaComponent,
    cartaReservarRestauranteDialog,
    gastronomyReservarRestauranteDialog,
    JardinComponent,
    ListadoCaracteristicasComponent,
    removeCaracteristicasDialog,
    NewCaracteristicaComponent,
    EditCaracteristicaComponent,
    EditServicioComponent,
    NewServicioComponent,
    ListadoServiciosComponent,
    removeServiciosDialog,
    RoomserviceComponent,
  ],
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    LayoutModule,
    AppRoutingModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    MDBBootstrapModule.forRoot(),
    MaterialFileInputModule,
    ScrollToModule.forRoot(),
    AgmJsMarkerClustererModule,
    SlickCarouselModule,
    InViewportModule,
    // Api Key Google Maps
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyComp051spHGmL4CV5ddkvRj-oHbkd-n3k'
    }),
    NgxMatMomentModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    OrderModule,
  ],
  providers: [
    /* {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    { provide: MAT_DATE_FORMATS, useValue: DateFormat }, */
    GlobalUtilities,
    CookieService,
    DatePipe,
    {
      provide: LOCALE_ID,
      useValue: 'es'
    },
    {provide: 'isBrowser', useValue: true },
    /* {provide: 'googleTagManagerId',  useValue: 'GTM-5DVNQ58'}, */
    LazyLoadScriptService,
    DynamicScriptLoaderService,
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  ],
  entryComponents: [
    AdminContactoComponent,
    fullMessageDialog,
    addRelatedDialogNewBlog,
    addRelatedDialogEditBlog,
    texto_legal_dialog,
    texto_legal_dialog_contacto,
    texto_legal_dialog_partial_contacto,
    texto_legal_menu_dialog,
    cartaReservarRestauranteDialog,
    gastronomyReservarRestauranteDialog,
    menuReservarRestauranteDialog,
    removeCaracteristicasDialog,
    removeServiciosDialog,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}