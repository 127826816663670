import { Component, OnInit, Renderer2, PLATFORM_ID, Inject } from '@angular/core';
import { BlogService } from '../utilities/servicios/blog.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  noticias: any;
  destacados: any;
  p: any;
  fragment: any;
  isBrowser;
  
  slideCaracteristicasConfig: any = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 500,
    infinite: true,
    autoplay: false,
    adaptiveHeight: false,
    dots: false,
    fade: false,
    cssEase: 'linear',
    rows: 0,
    centerMode: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          centerPadding: "0px",
        }
      },
    ]
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private blogService: BlogService,
    private _scrollToService: ScrollToService,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
   }

  ngOnInit() {
    this.getBlogs();
    this.getDestacados();

    $("#headerForm").addClass('change-color')
  }

  ngAfterViewInit() {

  }

  onPageChange(page: number) {
    try {
      const config: ScrollToConfigOptions = {
        target: this.fragment
      };

      this._scrollToService.scrollTo(config);
    } catch (e) { }
  }

  getBlogs(): void {
    this.blogService.getBlogs()
      .subscribe(data => {
        this.noticias = data.blogs.filter((items) => items.activo === true && items.lang === sessionStorage.getItem('language'));
      });
  }

  getDestacados(): void {
    this.blogService.destacados()
      .subscribe(data => {
        this.destacados = data.blogs.filter((items) => items.activo === true && items.lang == sessionStorage.getItem('language'));
      });
  }

  beforeChangeBlog(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#partialBlog .carouselShell .slick-slide")
          .css('transform', 'scale(0.7)');
    }
  }

  afterChangeBlog(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#partialBlog .carouselShell .slick-slide")
          .css('transform', 'scale(0.8)');
    }
  }
}
