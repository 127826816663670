import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { HabitacionService } from '../utilities/servicios/habitacion.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-detalle-habitacion',
  templateUrl: './detalle-habitacion.component.html',
  styleUrls: ['./detalle-habitacion.component.scss']
})
export class DetalleHabitacionComponent implements OnInit {
  habitacion: any;
  isBrowser;
  fragment: any;

  carouselConfig: any = {
    centerMode: true,
    centerPadding: "0px",
    cssEase: "ease",
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
          centerMode: false,
        }
      },
    ]
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private HabitacionService: HabitacionService,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId,
    private _scrollToService: ScrollToService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    //Same URL Router Events
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(async (event: NavigationEnd) => {
      this.getHabitacion();
    });
    //END Same URL Router Events
  }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    $("#headerForm").addClass('change-color')
  }

  getHabitacion(): void {
    let titulo = this.route.snapshot.paramMap.get('titulo');
    this.HabitacionService.getHabitacionByName(String(titulo))
      .subscribe(data => {
        if (data.habitacion.length > 0) {
          this.habitacion = data.habitacion[0];
        } else {
          // window.location.href = '/404';
        }
      });
  }

  beforeChangeCaracteristicas(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#caracteristicas .carouselShell .slick-slide")
          .css('transform', 'scale(0.7)');
    }
  }

  afterChangeCaracteristicas(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#caracteristicas .carouselShell .slick-slide")
          .not('.slick-center').not('.slick-active').css('transform', 'scale(1)');
    }
  }

  ngAfterViewInit(): void {
    try {
      const config: ScrollToConfigOptions = {
        target: this.fragment
      };

      this._scrollToService.scrollTo(config);
    } catch (e) { }
  }

  checkAvailability() {
    let url = "https://direct-book.com/properties/hotelmirlodirect";
    window.open(url);
  }
}
