import { Component, OnInit } from '@angular/core';
/* import { LazyLoadScriptService } from '../../utilities/servicios/lazy-load-script.service'; */
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-roomservice',
  templateUrl: './roomservice.component.html',
  styleUrls: ['./roomservice.component.scss']
})
export class RoomserviceComponent implements OnInit {

  idioma:any;
  idiomaActivo = "es";
  urlLangCap:any;

  constructor(
    public translate: TranslateService,
  ) { }

  ngOnInit() {
  }

  ngAfterContentInit() {
    let language;

    /* URL param capture */
    let URL_loaded = window.location.toString();
    let URL_tmp = URL_loaded.split("=");
    this.urlLangCap = URL_tmp[1];

    /* console.log("idioma activo");
    console.log(sessionStorage.getItem('language')); */

    if (sessionStorage.getItem('language') === null && this.urlLangCap === undefined)
    {
      /* No existe ningún idioma, ni en sessionStorage ni en urlLangCap */
      /* Predeterminamos ES */
      language = "es";
      this.idiomaActivo = language;
      sessionStorage.setItem('language',language);
      this.translate.use(sessionStorage.getItem('language'));
      
    }else{
      /* Existe un idioma predeterminado  */
      if (this.urlLangCap !== undefined)
      {
        /* console.log("tenemos url"); */
        language = this.urlLangCap;
        sessionStorage.setItem('language',language);
        this.translate.use(sessionStorage.getItem('language'));
        this.idiomaActivo = sessionStorage.getItem('language');
      }

      if (sessionStorage.getItem('language') !== null)
      {
        /* console.log("tenemos sessionStorage");
        console.log(sessionStorage.getItem('language')) */
        language = sessionStorage.getItem('language');
        this.idiomaActivo = language;
        this.translate.use(sessionStorage.getItem('language'));
      }      
    }

  /* this.setLogo();

  this.lazyLoadService.loadScript('https://widget.siteminder.com/ibe.min.js')
    .subscribe(async _ => {

    }); */
    
}
}
