import { Component, OnInit, Renderer2, PLATFORM_ID, Inject, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BlogService } from '../../utilities/servicios/blog.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ArraysService } from '../../utilities/servicios/arrays.service'

@Component({
  selector: 'app-partial-blog',
  templateUrl: './partial-blog.component.html',
  styleUrls: ['./partial-blog.component.scss']
})
export class PartialBlogComponent implements OnInit {
  isBrowser;
  noticias: any = [];
  titulo: any;
  language: string = '';

  carouselConfig: any = {
    centerMode: true,
    centerPadding: "0px",
    cssEase: "ease",
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: false,
    
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
          centerMode: false,
        }
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
          centerPadding: "0px",
          centerMode: false,
        }
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2,
          /* centerPadding: "0px", */
          /* centerMode: false, */
        }
      },
    ]
  };

  constructor(
    private blogService: BlogService,
    private arraysService: ArraysService,
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.language = sessionStorage.getItem('language');
    if (this.router.url.includes("/blog/"))
      this.getRelacionados();
    else
      this.destacados();
  }


  ngAfterContentInit() {
    if (this.router.url.includes("/blog/")) {
      $("#tituloGenerico").hide();
      $("#tituloRelacionados").show();
    } else {
      $("#tituloRelacionados").hide();
      $("#tituloGenerico").show();
    }
  }

  beforeChangeBlog(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#partialBlog .carouselShell .slick-slide")
          .css('transform', 'scale(0.7)');
    }
  }

  afterChangeBlog(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#partialBlog .carouselShell .slick-slide")
          .css('transform', 'scale(0.8)');
    }
  }

  // Patch cta primer slide infinite slide
  navigateClonedSlide(habitacion) {
    let nameInRoute = [].slice.call(habitacion.srcElement.classList).slice(-1)[0];
    // this.router.navigate([nameInRoute]);
    this.router.navigateByUrl('/404', { skipLocationChange: true }).then(() => {
      this.router.navigate([nameInRoute]);
    });
  }

  slickInit(e) {
    const elements = e.event.target.lastChild.firstChild.getElementsByClassName('dynamicCTA');
    // let elements: any = $("#partialBlogCarousel").find('.dynamicCTA');
    for (const element of elements) {
      //fer bind al CTA amb la funció "navigate"
      element.addEventListener('click', this.navigateClonedSlide.bind(this));
    }
  }
  // End patch cta primer slide infinite slide

  destacados(): void {
    this.blogService.destacados()
      .subscribe(data => {
        //Recuperar los elementos destacados activos y que no tengan la url del actual
        let titulo = this.route.snapshot.paramMap.get('titulo');
        this.noticias = data.blogs.filter((items) => items.activo === true && items.lang === sessionStorage.getItem('language'));
        /* this.noticias = data.noticias.filter((items) => items.activo === true && items.meta.meta_url != titulo && items.lang == sessionStorage.getItem('language')); */
        /* Shuffle noticias */
        this.arraysService.shuffle(this.noticias);
      });
  }

  getRelacionados() {
    let titulo = this.route.snapshot.paramMap.get('titulo');
    this.blogService.getBlogByName(String(titulo))
      .subscribe(data => {
        if (data.blog.length > 0 && data.blog[0].activo) {
          this.noticias = data.blog[0].relacionados.filter((items) => items.activo === true && items.lang === sessionStorage.getItem('language'));
        }
      });
  }
}
