import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { ContactoService } from '../utilities/servicios/contacto.service';
import { FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-gastronomia',
  templateUrl: './gastronomia.component.html',
  styleUrls: ['./gastronomia.component.scss']
})
export class GastronomiaComponent implements OnInit {
  isBrowser;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  slides = [
    '/assets/images/default/nuestra-gastronomia_slide@2x.jpg'
  ]

  gastronomiaSlides = [
    '/assets/images/default/gastronomia1.jpg',
    '/assets/images/default/gastronomia2.jpg',
    '/assets/images/default/gastronomia3.jpg',
    // '/assets/images/default/gastronomia4.jpg',
  ]

  slidePrincipalConfig: any = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 500,
    infinite: true,
    autoplay: false,
    adaptiveHeight: false,
    dots: false,
    fade: true,
    cssEase: 'linear',
    rows: 0,
  };

  carouselConfig: any = {
    centerMode: true,
    centerPadding: "0px",
    cssEase: "ease",
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
          centerMode: false,
        }
      },
    ]
  };

  ngOnInit() {
  }

  beforeChange(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#intro .carouselShell .slick-slide").css('transform', 'scale(0.7)');
    }
  }

  afterChange(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#intro .carouselShell .slick-slide").not('.slick-center').not('.slick-active').css('transform', 'scale(1.5)');
    }
  }

  beforeChangeGastronomia(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#gastronomia .carouselShell .slick-slide")
          .css('transform', 'scale(0.7)');
    }
  }

  afterChangeGastronomia(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#gastronomia .carouselShell .slick-slide")
          .css('transform', 'scale(0.8)');
    }
  }

  // Patch cta primer slide infinite slide
  navigateClonedSlide(habitacion) {
    let nameInRoute = [].slice.call(habitacion.srcElement.classList).slice(-1)[0];
    this.router.navigate([nameInRoute]);
  }

  slickInit(e) {
    const elements = e.event.target.lastChild.firstChild.getElementsByClassName('dynamicCTA');
    for (const element of elements) {
      //fer bind al CTA amb la funció "navigate"
      element.addEventListener('click', this.navigateClonedSlide.bind(this));
    }
  }
  // End patch cta primer slide infinite slide

  openReservarRestauranteDialog(titulo, mensaje): void {
    const dialogRef = this.dialog.open(gastronomyReservarRestauranteDialog, {
      width: '800px',
      hasBackdrop: true,
      panelClass: 'advertenciaPanel'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}

export interface DialogData {
  titulo: string;
  mensaje: string;
}

@Component({
  selector: 'gastronomyReservarRestauranteDialog',
  templateUrl: '../carta/reservarRestauranteDialog.html',
  styleUrls: ['../carta/reservarRestauranteDialog.scss']
})

export class gastronomyReservarRestauranteDialog {

  constructor(
    public dialogRef: MatDialogRef<gastronomyReservarRestauranteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private contactoService: ContactoService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    // this.onChanges();
  }

  // Open the picker at this (March 15 2019, 10:30) moment
  public startAt = new Date(2019, 2, 15, 10, 30);

  // Min moment: March 15 2019, 10:30
  public min = new Date(2019, 2, 15, 10, 30);

  // Max moment: March 15 2019, 20:30
  public max = new Date(2019, 2, 15, 22, 30);

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  reservarRestauranteForm = this.fb.group({
    nombre: ['', Validators.required],
    apellidos: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    telefono: ['', [Validators.required, Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')]],
    fecha: ['', Validators.required],
    hora: ['', Validators.required],
  })

  validateForm() {
    if (this.reservarRestauranteForm.invalid) {
      this.reservarRestauranteForm.get('nombre').markAsTouched();
      this.reservarRestauranteForm.get('apellidos').markAsTouched();
      this.reservarRestauranteForm.get('email').markAsTouched();
      this.reservarRestauranteForm.get('telefono').markAsTouched();
      this.reservarRestauranteForm.get('fecha').markAsTouched();
      this.reservarRestauranteForm.get('hora').markAsTouched();
      return;
    }
    this.onSubmit()
  }

  get nombre() { return this.reservarRestauranteForm.get('nombre'); }
  get apellidos() { return this.reservarRestauranteForm.get('apellidos'); }
  get email() { return this.reservarRestauranteForm.get('email'); }
  get telefono() { return this.reservarRestauranteForm.get('telefono'); }
  get fecha() { return this.reservarRestauranteForm.get('fecha'); }
  get hora() { return this.reservarRestauranteForm.get('hora'); }

  onSubmit() {
    let fecha: any = new Date(this.reservarRestauranteForm.value.fecha);
    fecha = fecha.getDate() + "/" + (fecha.getMonth() + 1) + "/" + fecha.getFullYear();
    this.reservarRestauranteForm.value.fecha = fecha;

    let hora: any = new Date(this.reservarRestauranteForm.value.hora._d);
    hora = hora.getHours() + ":" + hora.getMinutes();
    this.reservarRestauranteForm.value.hora = hora;

    this.contactoService.reservarRestaurante(this.reservarRestauranteForm.value).subscribe(data => {
      this.openSnackBar("La reserva se ha realizado correctamente", "Aceptar");
      this.closeDialog();
    }, err => {
      this.openSnackBar("No se ha podido completar la reserva", "Aceptar");
    })
  }
}
