import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ContactoService } from '../utilities/servicios/contacto.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import textos_legales from '../textos_legales.json';
import mapStyle from './mapStyle.json';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})

export class ContactoComponent implements OnInit {
  textos = textos_legales;
  styles = mapStyle;
  zoom = 15;
  defaultLat = 41.41348;
  defaultLong = 2.1346098;
  openedWindow: any; // alternative: array of numbers

  i18nTexts: {[key: string]: string} = {
    "Contacto_debes_aceptar_politica_privacidad": "",
    "Contacto_aceptar": "",
  }

  contactoForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    telefono: ['', [Validators.required, Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')]],
    mensaje: ['', Validators.required],
    avisolegal: [false, Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private contactoService: ContactoService,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    for (const key in this.i18nTexts) {
      this.translateService.get(key).subscribe((txt: string) => {
        this.i18nTexts[key] = txt;
      });
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  openDialog(titulo, mensaje): void {
    const dialogRef = this.dialog.open(texto_legal_dialog_contacto, {
      width: '800px',
      height: '500px',
      data: { titulo: titulo, mensaje: mensaje },
      panelClass: 'advertenciaPanel'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  validateForm() {
    if (!this.contactoForm.value.avisolegal) {
      this.openSnackBar(this.i18nTexts['Contacto_debes_aceptar_politica_privacidad'], this.i18nTexts['Contacto_aceptar']);
      return;
    }

    if (this.contactoForm.invalid) {
      this.contactoForm.get('email').markAsTouched();
      this.contactoForm.get('telefono').markAsTouched();
      this.contactoForm.get('mensaje').markAsTouched();
      this.contactoForm.get('avisolegal').markAsTouched();
      return;
    }
    this.onSubmit()
  }

  get email() { return this.contactoForm.get('email'); }
  get telefono() { return this.contactoForm.get('telefono'); }
  get mensaje() { return this.contactoForm.get('mensaje'); }
  get avisolegal() { return this.contactoForm.get('avisolegal'); }

  onSubmit() {
    this.contactoService.createContactos(this.contactoForm.value).subscribe(data => {
      this.router.navigate(['contacto/gracias'])
    }, err => {

    })
  }
}


export interface DialogData {
  titulo: string;
  mensaje: string;
}
@Component({
  selector: 'textoLegalDialog',
  templateUrl: '../textoLegalDialog.html',
})

export class texto_legal_dialog_contacto {

  constructor(
    public dialogRef: MatDialogRef<texto_legal_dialog_contacto>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  closeDialog() {
    this.dialogRef.close();
  }

}
