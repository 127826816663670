import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { AuthService } from './utilities/servicios/auth.service';
import { Router, NavigationEnd, ActivationEnd, ActivationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { HostListener } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  titulo: any;
  admin: any;
  roomservice: any = false;
  Adminlogin;
  isBrowser;
  mobileGrid;
  mobile;

  constructor(
    public router: Router,
    private auth: AuthService,
    public translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    translate.addLangs(['es', 'en', 'fr']);
    translate.setDefaultLang('es');
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.getScreenSizeStart();
      this.getScreenSize();
      //Google Analytics Router Events
      // router.events.subscribe(event => {
      //   if (event instanceof NavigationEnd) {
      //     (<any>window).ga('set', 'page', event.urlAfterRedirects);
      //     (<any>window).ga('send', 'pageview');
      //   }
      // });
      //END Google Analytics Router Events

      //Check Session Router Events
      router.events.pipe(
        filter(event => event instanceof ActivationEnd)
      ).subscribe(async (event: ActivationEnd) => {
        this.titulo = event.snapshot.data['title'];
        if (this.router.url.indexOf('/admin') > -1) {
          var checkSession = await this.auth.checkSession(true)
          if (checkSession) {
            if (this.router.url.indexOf('/admin/login') == 0) {
              this.admin = 'admin';
              this.router.navigate(['/admin']);
            }
            this.admin = 'admin';
          } else {
            this.admin = 'login';
            if (this.router.url.indexOf('/admin/login') != 0) this.router.navigate(['/admin/login'])
          }
        } else {
          this.admin = 'public';
          /* console.log(this.router.url); */
          if (this.router.url == '/menu')
          {
            /* console.log("roomservice url"); */
            /* this.roomservice = true; //Ocultar menú web en sección cartas*/
            this.roomservice = false;
            /* console.log('[this.roomservice] -> '+ this.roomservice); */
          }else{
            this.roomservice = false;
            /* console.log('[this.roomservice] -> '+ this.roomservice); */
          }
          
        }
      });
      //END Check Session Router Events

      //SCROLL TOP ON ROUTER NAV
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0)
      });
    }
  }

  ngOnInit() {
    //Show Header on Home
    if (this.isBrowser) {
      if (window.screen.width < 1163) {
        this.mobile = true;
      }
    }
    this.admin = 'public';
    /* console.log(this.admin); */
  }

  admin_logout() {
    this.auth.logout(true);
  }

  // Grid Events
  scrHeight: any;
  scrWidth: any;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    if (this.isBrowser) {
      this.scrHeight = window.innerHeight;
      this.scrWidth = window.innerWidth;
      if (this.scrWidth <= 768)
        this.mobileGrid = true;
      else
        this.mobileGrid = false;

      if (this.scrWidth <= 1163)
        this.mobile = true;
      else
        this.mobile = false;
    }
  }

  getScreenSizeStart() {
    if (this.isBrowser) {
      this.scrHeight = window.innerHeight;
      this.scrWidth = window.innerWidth;
      if (this.scrWidth <= 768) this.mobileGrid = true;
      else this.mobileGrid = false;
    }
  }
}
