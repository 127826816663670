import { Component, OnInit, Renderer2, PLATFORM_ID, Inject } from '@angular/core';
import { HabitacionService } from '../utilities/servicios/habitacion.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-habitaciones',
  templateUrl: './habitaciones.component.html',
  styleUrls: ['./habitaciones.component.scss']
})
export class HabitacionesComponent implements OnInit {
  habitaciones: any = [];
  isBrowser;
  slides = [
    '/assets/images/default/Slide_Rooms1.jpg',
    '/assets/images/default/3.jpg',
    '/assets/images/default/Slide_Rooms3.jpg',
    '/assets/images/default/Slide_Rooms4.jpg',
    '/assets/images/default/Slide_Rooms5.jpg',
  ]

  slidePrincipalConfig: any = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 500,
    infinite: true,
    autoplay: false,
    adaptiveHeight: false,
    dots: false,
    fade: true,
    cssEase: 'linear',
    rows: 0,
  };

  carouselConfig: any = {
    centerMode: true,
    centerPadding: "0px",
    cssEase: "ease",
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
          centerMode: false,
        }
      },
    ]
  };

  constructor(
    private HabitacionService: HabitacionService,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.getHabitaciones();
  }

  getHabitaciones(): void {
    this.HabitacionService.getHabitaciones()
      .subscribe(data => {
        this.habitaciones = data.habitaciones.filter((items) => items.lang == sessionStorage.getItem("language"));
        /* this.habitaciones = data.habitaciones; */
      })
  }

  beforeChangeRoomBlock(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#" + e.event.target.id + " .slick-slide").css('transform', 'scale(0.7)');
    }
  }

  afterChangeRoomBlock(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#" + e.event.target.id + " .slick-slide").not('.slick-center').not('.slick-active').css('transform', 'scale(1)');
    }
  }
}
