import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hotel',
  templateUrl: './hotel.component.html',
  styleUrls: ['./hotel.component.scss']
})
export class HotelComponent implements OnInit {
  isBrowser;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  slides = [
    '/assets/images/default/1.jpg',
    '/assets/images/default/2.jpg',
    '/assets/images/default/3.jpg',
    '/assets/images/default/4.jpg',
    '/assets/images/default/5.jpg',
    '/assets/images/default/6.jpg',
    '/assets/images/default/7.jpg',
    '/assets/images/default/8.jpg'
  ]

  arquitectura = [
    '/assets/images/default/hotel_arquitectura1.jpg',
    '/assets/images/default/hotel_arquitectura2.jpg',
    '/assets/images/default/hotel_arquitectura3.jpg',
    '/assets/images/default/hotel_arquitectura4.jpg',
  ]

  especiales = [
    /* '/assets/images/default/especiales3.jpg', */
    '/assets/images/default/especiales6.jpg',
    '/assets/images/default/especiales2.jpg',
    '/assets/images/default/especiales1.png',
    
  ]

  slidePrincipalConfig: any = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 500,
    infinite: true,
    autoplay: false,
    adaptiveHeight: false,
    dots: false,
    fade: true,
    cssEase: 'linear',
    rows: 0,
  };

  carouselConfig: any = {
    centerMode: true,
    centerPadding: "0px",
    cssEase: "ease",
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
          centerMode: false,
        }
      },
    ]
  };

  ngOnInit() {
  }

  beforeChange(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#intro .carouselShell .slick-slide").css('transform', 'scale(0.7)');
    }
  }

  afterChange(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#intro .carouselShell .slick-slide").not('.slick-center').not('.slick-active').css('transform', 'scale(1)');
    }
  }

  beforeChangeGastronomia(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#gastronomia .carouselShell .slick-slide")
          .css('transform', 'scale(0.8)');
    }
  }

  afterChangeGastronomia(e) {
    if (this.isBrowser) {
      if (window.innerWidth > 991)
        $("#gastronomia .carouselShell .slick-slide")
          .css('transform', 'scale(0.8)');
    }
  }

  // Patch cta primer slide infinite slide
  navigateClonedSlide(habitacion) {
    
    let nameInRoute = [].slice.call(habitacion.srcElement.classList).slice(-1)[0];
       this.router.navigate([nameInRoute]);
  }

    slickInit(e) {
    const elements = e.event.target.lastChild.firstChild.getElementsByClassName('dynamicCTA');
    for (const element of elements) {
      //fer bind al CTA amb la funció "navigate"
      element.addEventListener('click', this.navigateClonedSlide.bind(this));
    }
  }
  // End patch cta primer slide infinite slide
}
